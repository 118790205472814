<!-- 页面顶部栏区域 -->
<template>
  <div class="header_view">
    <img src="https://static3.biyao.com/pc/common/img/master/logo.png" alt="" />
    <div class="line_v"></div>
    <h1>欢迎使用-会话中心</h1>
    <div style="margin-right: 20px;margin-left: auto;">
      <span style="font-size: 14px;margin-right: 10px;">{{ accountInfo.nickname }}，欢迎您！</span>
      <el-button type="primary" size="small" @click="openModalAction">设置</el-button>
      <el-button type="default" size="small" @click="outLoginAction">退出登录</el-button>
    </div>
    <el-dialog title="提示" :visible.sync="openModal" width="500px">
      <div style="text-align: center;">
        <span style="margin-right: 4px;">展示</span>
        <el-input-number style="width:130px" size="small" placeholder="请输入(1-90)数字" :value="rangeNum" @change="rangeChange" :min="1" :max="90" :step="1" :controls="false" />
        <span style="font-weight: bold;margin-left: 4px;">天内</span>
        <span>的待回复消息</span>
      </div>
      <div style="font-size: 12px; color: #c4c0c0;margin-top: 10px;text-align: center;">当前时间向前追溯24小时为一天</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openModal = false">取 消</el-button>
        <el-button type="primary" @click="sureSettingAction">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
export default {
  props: ['accountInfo'],
  inject: ['changeAccount'],
  data() {
    return {
      openModal: false,
      rangeNum: 1,
      DRKEY: "$dataRange"
    };
  },
  mounted() {
    this.rangeNum = localStorage.getItem(this.DRKEY) || 1;
  },
  methods: {
    openModalAction() {
      this.openModal = true;
    },
    rangeChange(value) {
      if (!value && value != 0) {
        return;
      }
      if (value < 1) {
        value = 1;
      } else if (value > 90) {
        value = 90;
      }
      this.rangeNum = Math.floor(value);
    },
    sureSettingAction() {
      localStorage.setItem(this.DRKEY, `${this.rangeNum}`);
      this.openModal = false;
      this.$emit('refresh');
    },
    // 退出登录
    outLoginAction() {
      this.$alert('您确定要退出吗？', '提示', {
        confirmButtonText: '保持登录',
        cancelButtonText: "退出",
        showCancelButton: true,
        showClose: false,
        callback: action => {
          if (action == 'cancel') {
            this.changeAccount();
          }
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.header_view {
  height: 60px;
  background-color: white;
  border-bottom: 1px solid var(--boder-color);
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;

  img {
    height: 60%;
    margin-left: 30px;
  }

  .line_v {
    height: 40%;
    background-color: #c4c0c0;
    width: 2px;
    margin: auto 15px;
  }

  h1 {
    font-size: 20px;
    color: #333;
  }

}
</style>