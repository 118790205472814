import Vue from "vue";

export const Noti_Key = {
  /**
   * 选中了 某个小必姐下/默认联系人
  */ 
  Select_User: 'selectUserInfo',
  /**
   * 联系人被删除通知
  */ 
  User_Delete: 'userDelete',
  /**
   * 联系人状态发生变化通知
  */ 
  User_Change: "userChange",
   /**
   * 消息状态发生变化 触发
  */ 
   Message_Status_Change: "messageStatusChange",
   /**
   * 小必姐在线状态 发生变化触发
  */ 
   Xbj_Online_Status_Change: "xbjOnlineStatusChange",

};

export const Noti = new Vue();