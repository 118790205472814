<template>
  <div class="bg_view" v-loading="showLoading" @click.stop="emptyAction">
    <div class="menu_view">
      <div class="menu_item_view" :class="menuSelectIndex == 0 ? 'item_active' : ''" @click="menuSelectIndex = 0">商品</div>
      <div class="menu_item_view" :class="menuSelectIndex == 1 ? 'item_active' : ''" @click="menuSelectIndex = 1">活动</div>
    </div>
    <div class="search_view" v-show="menuSelectIndex == 0">
      <el-input placeholder="请输入商品名称/ID" v-model="searchText" size="small" :disabled="showLoading" maxlength="50">
        <div class="search_icon" slot="suffix" @click="searchAction">
          <i class="el-icon-search"></i>
        </div>
      </el-input>
    </div>
    <div class="list_view" :style="menuSelectIndex == 1 ? 'height: calc(100% - 60px)' : ''" @scroll="onScrollAction">
      <span v-show="menuSelectIndex == 0">
        <div class="item_view" v-for="item in dataList" :key="item.suId">
          <div style="line-height: 30px;">商品ID：{{ item.spuId }}</div>
          <img class="goods_img" :src="item.img" alt="">
          <div class="name_tx">{{ item.title }}</div>
          <div class="price_tx">售价：￥{{ item.price }}</div>
          <img class="icon_share" :src="require('@/assets/icon_share.svg')" alt="" @click.stop="sendMessageAction(item)">
        </div>
        <el-empty v-if="!dataList.length" description="暂无数据" :image="require('@/assets/icon_empty.png')" :image-size="80"></el-empty>
      </span>
      <span v-show="menuSelectIndex == 1">
        <div class="activity_item_view" v-for="(item) in activityDataList" :key="item.cardId">
          <div class="name_tx">{{ item.cardName }}</div>
          <div class="content_tx">
            <span>{{ item.contentTxTop }}</span>
            <span>{{ item.contentTxBottom }}</span>
          </div>
          <img v-show="item.isSend != 1" class="icon_share" :src="require('@/assets/icon_share_white.svg')" alt="" @click.stop="sendActivityMessageAction(item)">
          <img v-show="item.isSend == 1" class="icon_is_send" :src="require('@/assets/icon_send.png')" alt="">
        </div>
        <div class="loading_view" v-if="activityDataList.length">
          <span v-if="!isLastPage">
            <i class="el-icon-loading"></i>
            <span>加载中...</span>
          </span>
          <span v-else>—没有更多了—</span>
        </div>
        <el-empty v-if="!activityDataList.length" description="暂无数据" :image="require('@/assets/icon_empty.png')" :image-size="80"></el-empty>
      </span>
    </div>
    <div class="bottom_view">
      <el-button type="primary" size="mini" @click="closeAction">关闭</el-button>
    </div>
  </div>
</template>

<script>
import API from '@/common/axios';

export default {
  props: ['selectUserInfo'],
  data() {
    return {
      searchText: '',
      dataList: [],
      showLoading: false,
      menuSelectIndex: 0,
      activityDataList: [],
      isLastPage: false,
      pageIndex: 1
    };
  },
  watch: {
    menuSelectIndex(val) {
      if (val == 1 && this.activityDataList.length == 0) {
        this.$el.getElementsByClassName('list_view')[0].scrollTop = 0;
        this.getActivityList();
      }
    },
    selectUserInfo(val) {
      if (val.isSame) {
        return;
      }
      this.menuSelectIndex = 0;
      this.dataList = [];
      this.activityDataList = [];
      this.pageIndex = 1;
      this.isLastPage = false;
      this.searchText = '';
      this.showLoading = false;
      this.cancelToken && this.cancelToken();
    }
  },
  inject: ['sendMessage'],
  methods: {
    onScrollAction(e) {
      if (this.isLastPage || this.cancelToken || this.menuSelectIndex != 1) {
        return;
      }
      let container = e.currentTarget;
      const scrollTop = container.scrollTop;
      const clientHeight = container.clientHeight;
      const scrollHeight = container.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        this.getActivityList(this.pageIndex + 1);
      }
    },
    searchAction() {
      this.dataList = [];
      if (!this.searchText.length) {
        return;
      }
      this.showLoading = true;
      API({
        // url: "https://mock.apifox.com/m1/4186304-0-default/getProduct",
        url: "/applet/getAppletProductCard.do ",
        data: {
          productIdName: this.searchText
        },
      }).then(res => {
        if (res.success == 1) {
          this.dataList = res.data;
        } else {
          this.$message.error(res.errMsg);
        }
      }).finally(_ => this.showLoading = false);
    },
    getActivityList(pageIndex = 1) {
      if (this.cancelToken) this.cancelToken();
      if (this.isLastPage && this.activityDataList.length) return;
      let { xbjId, externalUserid, } = this.selectUserInfo;
      API({
        // url: "https://mock.apifox.com/m1/4186304-0-default/activitycard",
        url: "/applet/getAppletActivityCard.do",
        data: {
          pageIndex: pageIndex,
          pageSize: 20,
          externalUserId: externalUserid,
          xbjId
        },
        cancelToken: new API.CancelToken(c => this.cancelToken = c)
      }).then(res => {
        if (res.success == 1) {
          this.pageIndex = pageIndex;
          let { pageCount, objs } = res.data;
          if (pageIndex >= pageCount) this.isLastPage = true;
          this.activityDataList.push(...objs);
          if (this.activityDataList.length < 10 && !this.isLastPage) {
            setTimeout(() => {
              this.getActivityList(this.pageIndex + 1);
            });
          }
        } else {
          this.$message.error(res.errMsg);
        }
      }).finally(_ => this.cancelToken = null);
    },
    closeAction() {
      this.$emit('close');
    },
    emptyAction() { },
    // 发送商品小程序卡片
    sendMessageAction(item) {
      this.sendMessage([{
        miniProgrameName: '必要商城 Biyao',
        imgUrl: item.img,
        miniProgrameTitle: item.title,
        path: item.path,
        type: "miniprogram"
      }]);
      this.closeAction();
    },
    // 发送活动小程序卡片
    async sendActivityMessageAction(item) {
      if (this.isCanSentFlag) {
        return;
      }
      this.isCanSentFlag = true;
      let res;
      let { xbjId, externalUserid, } = this.selectUserInfo;
      try {
        res = await API({ url: "/applet/checkCardShare.do", data: { id: item.cardId, xbjId, externalUserId: externalUserid } });
      } catch (_) {
        res = { data: { canSend: 0, message: "请检查您的网络连通性！" } };
      }
      this.isCanSentFlag = false;
      if (res.data.canSend == 0) {
        this.$message.warning(res.data.message);
        return;
      }
      this.sendMessage([{
        miniProgrameName: '必要商城 Biyao',
        imgUrl: item.img,
        miniProgrameTitle: item.title,
        path: item.path,
        type: "miniprogram"
      }]);
      API({
        url: "/applet/shareCardSuccess.do",
        data: { id: item.cardId, xbjId, externalUserId: externalUserid }
      }).then(res => {
        if (res.success == 1) {
          item.isSend = 1;
          this.closeAction();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 1px;
}

.bg_view {
  width: 250px;
  height: 450px;
  overflow-y: auto;
  position: relative;

  .menu_view {
    height: 30px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .menu_item_view {
      border-bottom: 2px solid white;
      font-size: 14px;
      line-height: 20px;
      color: #333;
      cursor: pointer;
      user-select: none;

      &.item_active {
        color: #784CFA;
        border-color: #784CFA;
      }
    }
  }

  .search_view {
    height: 44px;
    position: sticky;
    top: 30px;
    left: 0;
    background-color: white;

    .search_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      cursor: pointer;

      &:hover {
        color: #409EFF;
      }
    }
  }

  .list_view {
    height: calc(100% - 104px);
    overflow-y: auto;

    .item_view {
      position: relative;
      height: 114px;
      border-bottom: 1px solid var(--boder-color);

      .goods_img {
        width: 80px;
        height: 80px;
        position: absolute;
        left: 0px;
        top: 30px;
        object-fit: contain;
        background-color: #ccc;
        border-radius: 4px;
      }

      .name_tx {
        font-size: 14px;
        color: #333;
        margin-left: 90px;
        margin-right: 10px;
        text-align: justify;
        word-break: break-all;
        font-weight: bold;
      }

      .price_tx {
        font-size: 12px;
        position: absolute;
        bottom: 10px;
        left: 90px;
        color: #666;
      }

      .icon_share {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0;
        top: calc(50% + 15px);
        cursor: pointer;
      }
    }

    .activity_item_view {
      background-color: #784CFA;
      margin-top: 4px;
      border-radius: 4px;
      height: 80px;
      position: relative;

      .name_tx {
        font-size: 20px;
        color: white;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        float: left;
      }

      .icon_share {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 10px;
        top: calc(50% + 5px);
        cursor: pointer;
      }

      .icon_is_send {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 50px;
      }

      .content_tx {
        margin-left: 10px;
        font-size: 12px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-right: 30px;
        line-height: 25px;
        align-items: center;
      }
    }


    .loading_view {
      height: 30px;
      font-size: 12px;
      color: #999;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .bottom_view {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;

    /deep/ .el-button {
      width: 100%;
      height: 100%;
    }
  }
}
</style>