<!-- 消息列表 -->
<template>
  <div class="message_list_view">
    <div v-for="(item, index) in dataList" :class="`message_item_view ${item.isUser == 1 ? '' : 'message_item_view_right'}`" :key="`key_${index}`">
      <img class="user_img" :src="item.isUser == 1 ? item.userImg : require('@/assets/xbj_avatar.jpg')" :style="`${item.isUser != 1 ? 'cursor: pointer;' : ''}`" @click="avatarAction(item)" />
      <div class="info_view">
        <div class="user_name_tx">{{ item.name }}</div>
        <div class="time_tx">{{ item.time }}</div>
        <div class="message_content_view">
          <div class="status_view" v-if="item.isUser == 0">
            <i class="el-icon-close" style="color: #F56C6C" v-if="item.status == 'fail'"></i>
            <i class="el-icon-check" style="color: #67C23A" v-if="item.status == 'success' || !item.status"></i>
            <i class="el-icon-loading" style="color: #409EFF" v-if="item.status == 'loading'"></i>
          </div>
          <messgaeItem :msgObj="item.msgObj" :isUser="item.isUser" />
        </div>
      </div>
      <div v-if="item.isUser != 1 && item.isSop == 1" class="sop_view" @click="openSopAction(item)">
        <img :src="require('@/assets/icon_sop.png')" alt="">
      </div>
    </div>
    <div class="empty_view" v-if="dataList.length == 0">
      <el-empty :image="require('@/assets/icon_empty.png')" description="暂无数据"></el-empty>
    </div>
    <div style="height: 30px;"></div>
  </div>
</template>

<script>
import messgaeItem from './messgaeItem.vue';
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  },
  inject: ['openFriend', 'openSopMsg'],
  components: {
    messgaeItem
  },
  methods: {
    // 初始化
    init() {
      // 聊天框 自动滚动到底部
      setTimeout(() => {
        let msgDom = this.$el;
        msgDom.scrollTo({
          top: msgDom.scrollHeight,
        });
      });
    },
    avatarAction(item) {
      if (item.isUser == 1) {
        return;
      }
      this.openFriend();
    },
    openSopAction(item) {
      this.openSopMsg(item.sopType)
    }
  }
};
</script>

<style lang="scss" scoped>
.message_list_view {
  height: calc(100% - 50px - 260px);
  background-color: white;
  overflow-y: auto;

  .empty_view {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .message_item_view {
    width: 40%;
    padding: 10px;
    position: relative;

    .info_view {
      margin-left: 50px;
    }

    .user_img {
      width: 40px;
      height: 40px;
      border: 1px solid #c4c0c0;
      border-radius: 22px;
      position: absolute;
      left: 10px;
      top: 10px;
      object-fit: cover;
    }

    .user_name_tx {
      padding-top: 6px;
      font-size: 14px;
      color: #333;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 16px;
    }

    .time_tx {
      font-size: 12px;
      color: #666;
      margin: 4px 0 10px 0;
      line-height: 14px;
    }

    .message_content_view {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      display: flex;
      position: relative;
      align-items: center;

      .status_view {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        font-size: 20px;
        color: red;
      }
    }

    .sop_view {
      margin-right: 50px;
      cursor: pointer;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }


  .message_item_view_right {
    margin-left: calc(60% - 20px);
    text-align: right;

    .user_img {
      left: auto;
      right: 10px;
    }

    .info_view {
      margin-right: 50px;
      margin-left: 0;
    }

    .message_content_view {
      justify-content: flex-end;
    }
  }
}
</style>