<template>
  <div class="bg_view" v-loading="showLoading" element-loading-text="拼命加载中">
    <template v-if="accountInfo.is_xbj">
      <headerView :accountInfo="accountInfo" ref="header_view" @refresh="getXbjList" />
      <xbjListView ref="xbjListView" />
      <contentView @sendMessage="sendMessage" ref="contentView" />
      <webContentView ref="webContentView" />
    </template>
    <div class="no_auth_view" v-if="(accountInfo.nickname && !accountInfo.is_xbj) || loginErrorMessage">
      <img :src="require('@/assets/icon_empty.png')" alt="">
      <div>{{ loginErrorMessage || '当前员工名下没有已绑定的许愿~' }}</div>
      <el-button style="margin-top: 10px;" @click="changeLoginAccountAction" size="small">更换用户</el-button>
    </div>
  </div>
</template>

<script>
import headerView from './components/headerView.vue';
import xbjListView from './components/xbjListView.vue';
import contentView from './components/contentView/index.vue';
import webContentView from './components/webContentView/index.vue';
import API from '@/common/axios';
import { Noti } from '@/common/event';
import BYWebSocket from './common/socket';
import Cookies from 'js-cookie';
import Trace from '@/common/trace';
export default {
  components: {
    headerView,
    xbjListView,
    contentView,
    webContentView
  },
  provide() {
    return {
      // 打开朋友圈
      openFriend: this.openFriendAction,
      // 打开sop消息详情
      openSopMsg: this.openSopMsgAction,
      //打开小程序
      openWeAppMsg: this.openWeAppAcion,
      // 切换用户
      changeAccount: this.changeLoginAccountAction
    };
  },
  data() {
    return {
      // 账号信息
      accountInfo: {},
      showLoading: false,
      authKey: 'x-ai-sk',
      loginErrorMessage: ''
    };
  },
  mounted() {
    this.init();
  },
  destroyed() {
    Noti.$off();
    this.websocket && this.websocket.stop();
  },
  methods: {
    init() {
      let xKey = Cookies.get(this.authKey);
      if (!xKey) {
        window.location.href = `http://aiauth.biyao.com/?redirect_uri=${encodeURIComponent(window.location.origin)}`;
        return;
      }
      this.showLoading = true;
      API({
        url: `http://aiapi.biyao.com/token/getUserInfo?x-ai-sk=${xKey}`,
        method: "GET"
      }).then(res => {
        this.showLoading = false;
        if (res.status == 200) {
          // res.data.nickname = '赵晓峰';
          // res.data.is_xbj = true;
          // res.data.employee_id = 'B0489';
          this.accountInfo = res.data;
          localStorage.setItem('accountInfo', JSON.stringify(this.accountInfo));
          if (this.accountInfo.is_xbj) {
            this.$nextTick(_ => {
              this.getXbjList();
            });
            this.websocket = new BYWebSocket(`ws://msi.biyao.com/ws/pendingreply/${this.accountInfo.employee_id}`);
          }
        } else {
          this.loginErrorMessage = res.message || '登录发生错误，请切换用户重试~';
          // this.$message.error(res.message);
        }
      }).catch(err => {
        this.showLoading = false;
        this.$message.error('数据获取失败，请检查您的网络连通性！');
      });
    },
    getXbjList() {
      this.showLoading = true;
      let dataRange = this.$refs.header_view.rangeNum;
      API({
        url: '/pendingreply/queryByconditon.do',
        data: {
          dataRange,
          xbjName: this.accountInfo.nickname,
          employee_id: this.accountInfo.employee_id
        }
      }).then((result) => {
        if (result.success != 1) {
          this.$message.error(result.errMsg || '服务请求失败，请刷新浏览器重试！');
          return;
        }
        this.$refs.xbjListView.loadXbjList(result.data);
      }).finally(_ => {
        this.showLoading = false;
      });
    },
    // 发送消息
    sendMessage(data) {
      if (this.websocket.status != 'open') {
        this.$message.error('消息发送失败，请检查网络连通性！');
      } else {
        let msgObj = JSON.parse(data);
        msgObj.data.employeeId = this.accountInfo.employee_id;
        msgObj.data.employeeName = this.accountInfo.nickname;
        this.websocket.send(JSON.stringify(msgObj));
      }
    },
    // 打开朋友圈
    openFriendAction() {
      this.$refs.webContentView.openFriendAction();
    },
    // 打开sop消息详情
    openSopMsgAction(agentId) {
      this.$refs.webContentView.openSopMsgAction(agentId);
      Trace({
        operationType: 9,
      });
    },
    // 点击小程序信息
    openWeAppAcion(title, isUser) {
      this.$refs.webContentView.openWeAppAcion(title);
      Trace({
        operationType: 8,
      });
      if (isUser) {
        Trace({
          operationType: 11,
        });
      }
    },
    // 切换用户
    changeLoginAccountAction() {
      Cookies.remove(this.authKey, { domain: '.biyao.com' });
      window.location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
.bg_view {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no_auth_view {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #F56C6C;
  font-weight: bold;

  img {
    width: 180px;
    height: 180px;
  }
}
</style>
<style>
._view_can_select {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
</style>