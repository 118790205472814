<!-- 单个消息组件 -->
<template>
  <div v-if="msgObj">
    <!-- 文本消息 -->
    <div v-if="msgObj.type == 1 || msgObj.type == 3" class="message_tx _view_can_select" :style="`background-color: ${isUser == 1 ? '#e9e9e9' : '#91ea5d'}`" v-html="getTextMessageContent(msgObj.messageText)" @copy="textMessageCopyAction">
    </div>
    <!-- 图片消息 -->
    <div v-else-if="msgObj.type == 2" class="message_img">
      <div class="progress_view" v-if="msgObj.progressNum">
        <el-progress :stroke-width="4" type="circle" :width="40" :percentage="msgObj.progressNum"></el-progress>
      </div>
      <el-image fit="contain" :preview-src-list="[msgObj.imgUrl]" :src="msgObj.imgUrl">
        <div slot="placeholder" class="image-slot">图片加载中...</div>
      </el-image>
    </div>
    <!-- 语音消息 -->
    <div v-else-if="msgObj.type == 4" class="message_audio" @click="audioClick">
      <div :class="`icon_v ${audioStatus == 1 ? 'icon_play' : ''}`"></div>
      <audio :src="msgObj.audioUrl" @ended="audioEnd" @pause="audioEnd" @play="audioPlay" @loadedmetadata="onLoadedMetadata"></audio>
      <span class="audio_time_tx">{{ audioTotalTime }}</span>
    </div>
    <!-- 视频消息 -->
    <div v-else-if="msgObj.type == 5">
      <div class="message_video" @click="showVideoMessageModal = true">
        <div class="progress_view" v-if="msgObj.progressNum">
          <el-progress :stroke-width="4" type="circle" :width="40" :percentage="msgObj.progressNum"></el-progress>
        </div>
        <video :src="msgObj.videoUrl" width="100%" height="100%" style="pointer-events: none;border-radius: 4px;"></video>
        <img :src="require('@/assets/icon_video_play.png')" alt="" class="icon_play" />
        <!-- 下载按钮 -->
        <a :href="msgObj.videoUrl" download target="_blank" class="download_btn" @click.stop="() => {}">
          <img :src="require('@/assets/icon_video_download.svg')" alt="下载">
        </a>
      </div>
      <el-dialog title="视频播放" destroy-on-close :visible.sync="showVideoMessageModal" @close="showVideoMessageModal = false">
        <video :src="msgObj.videoUrl" width="100%" style="max-height: calc(100vh / 2)" controls autoPlay></video>
      </el-dialog>
    </div>
    <!-- 小程序消息 -->
    <div v-else-if="msgObj.type == 6" class="message_mini" @click="openWeappAction">
      <span class="name_tx">{{ msgObj.miniProgrameName }}</span>
      <div class="title_tx">{{ msgObj.miniProgrameTitle }}</div>
      <div class="bottom_view">
        <img class="icon_img" :src="require('@/assets/icon_weapp.png')" alt="" />
        <span>小程序</span>
      </div>
    </div>
    <!-- 文件消息 -->
    <div v-else-if="msgObj.type == 7" class="message_file">
      <div class="name_tx">{{ msgObj.fileName }}</div>
      <div class="size_tx">{{ msgObj.fileSize }}</div>
      <img class="icon_img" :src="require('@/assets/icon_file.png')" alt="" />
    </div>
    <!-- 链接消息 -->
    <div v-else-if="msgObj.type == 8" class="message_link" @click="openLinkAction">
      <div class="title_tx">{{ msgObj.linkTitle }}</div>
      <div class="desc_tx">{{ msgObj.linkDesc }}</div>
    </div>
    <!-- 其他不支持展示的消息 -->
    <div v-else class="message_tx" :style="`background-color: ${isUser == 1 ? '#e9e9e9' : '#91ea5d'}`">
      {{ `${msgObj.messageTypeName}: 暂不支持查看` }}
    </div>
  </div>
</template>

<script>
import { textMessageToHtml } from '../common/utils';
export default {
  props: {
    msgObj: {
      type: Object,
      default: () => { }
    },
    isUser: {
      type: Number,
      default: 0
    }
  },
  inject: ['openWeAppMsg'],
  data() {
    return {
      // 音频总时长
      audioTotalTime: '',
      // 语音 0 未播放 1播放中
      audioStatus: 0,
      // 展示视频播放弹窗
      showVideoMessageModal: false,
    };
  },
  methods: {
    // 文本类消息  匹配里面所有的表情为图片
    getTextMessageContent(text) {
      let escapedText = text.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\//g, '&#x2F;');
      return textMessageToHtml(escapedText);
    },
    audioClick(e) {
      let ad = e.currentTarget.getElementsByTagName('audio')[0];
      if (this.audioStatus == 1) {
        this.stopAudio(ad);
        return;
      }
      let playAudioDom = document.getElementById('message_audio');
      if (playAudioDom) {
        playAudioDom.id = '';
        playAudioDom.pause();
        playAudioDom.currentTime = 0;
      }
      ad.play()
        .then(() => ad.id = 'message_audio')
        .catch(() => {
          message.error('音频播放失败，请稍后重试！');
        });
    },
    audioEnd() {
      this.audioStatus = 0;
    },
    stopAudio(audio) {
      audio.pause();
      audio.currentTime = 0;
      this.audioStatus = 0;
    },
    audioPlay() {
      this.audioStatus = 1;
    },
    onLoadedMetadata(e) {
      if (e && e.target) {
        this.audioTotalTime = Math.ceil(e.target.duration).toString() + "''";
      }
    },
    openLinkAction() {
      window.open(this.msgObj.linkUrl, '_blank');
    },
    openWeappAction() {
      this.openWeAppMsg(this.msgObj.miniProgrameTitle, this.isUser);
    },
    // 文本消息拷贝
    textMessageCopyAction(event) {
      event.preventDefault();
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const selectedNodes = Array.from(range.cloneContents().childNodes);
        let clipboardText = '';
        for (const node of selectedNodes) {
          if (node.nodeType == Node.TEXT_NODE) {
            clipboardText += node.textContent;
          } else if (node.nodeName.toLowerCase() == 'br') {
            clipboardText += '\n';
          } else if (node.nodeName.toLowerCase() == 'img' && node.getAttribute('data-key')) {
            clipboardText += node.getAttribute('data-key');
          }
        }
        // 将转换后的文本写入剪贴板
        if (event.clipboardData.setData) {
          event.clipboardData.setData('text/plain', clipboardText);
        } else if (window.clipboardData) { // 对于老版本IE
          window.clipboardData.setData('Text', clipboardText);
        }
        selection.removeAllRanges();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.message_tx {
  padding: 8px;
  border-radius: 4px;
  background-color: #52c41a;
  max-width: 300px;
  word-break: break-all;
  text-align: justify;
  line-height: 20px;
  font-size: 14px;
}

.message_img {
  max-width: 200px;
  height: 200px;
  // height: auto;
  border: 1px solid #c4c0c0;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .image-slot {
    color: #666;
    font-size: 12px;
    min-width: 100px;
    text-align: center;
  }
}

.message_audio {
  margin: 10px 0;
  background-color: #52c41a;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  width: 150px;
  position: relative;

  .icon_v {
    margin: 0 10px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-image: url('~@/assets/icon_audio.png');
    background-size: 15px 100%;
  }

  .icon_play {
    animation: audio_am 1s linear infinite;
    -webkit-animation: audio_am 1s linear infinite;
  }

  .audio_time_tx {
    color: white;
    font-size: 12px;
    position: absolute;
    left: 35px;
    top: 0;
    line-height: 30px;
  }

  @keyframes audio_am {
    0% {
      width: 0;
    }

    100% {
      width: 15px;
    }
  }
}

.message_video {
  height: 200px;
  position: relative;
  cursor: pointer;
  font-size: 0;
  max-width: 300px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .icon_play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
  }

  .download_btn {
    position: absolute;
    right: 0px;
    bottom: 0px;
    background-color: white;
    height: 24px;
    width: 24px;
    text-align: center;
    border-top-left-radius: 4px;

    img {
      width: 16px;
      height: 16px;
      margin-top: 4px;
    }
  }

}


.message_mini {
  border-radius: 4px;
  border: 1px solid #c4c0c0;
  width: 200px;
  cursor: pointer;

  .name_tx {
    color: #c4c0c0;
    font-size: 12px;
    margin-left: 4px;
  }

  .title_tx {
    color: #333;
    font-size: 12px;
    line-height: 16px;
    margin: 4px 4px 0;
    word-break: break-all;
  }

  .bottom_view {
    display: flex;
    background-color: var(--boder-color);
    border-top: 1px solid var(--boder-color);
    font-size: 10px;
    height: 16px;
    align-items: center;
    margin-top: 4px;


    .icon_img {
      width: 10px;
      height: 10px;
      margin-left: 4px;
      margin-right: 4px;
      font-size: 0;
    }
  }
}

.message_file {
  border-radius: 4px;
  border: 1px solid #c4c0c0;
  min-height: 30px;
  padding: 8px 40px 8px 8px;
  position: relative;
  max-width: 200px;

  .name_tx {
    font-size: 12px;
    word-break: break-all;
    line-height: 14px;
  }

  .size_tx {
    font-size: 10px;
    color: #c4c0c0;
    margin-top: 4px;
  }

  .icon_img {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.message_link {
  border-radius: 4px;
  border: 1px solid #c4c0c0;
  min-height: 20px;
  padding: 10px;
  cursor: pointer;
  background-image: url('~@/assets/icon_link.png');
  background-size: 15px 15px;
  background-position: 100% 0;
  background-repeat: no-repeat;
  max-width: 300px;
  box-sizing: border-box;
  min-width: 150px;

  .title_tx {
    font-size: 12px;
    color: #333;
    word-break: break-all;
  }

  .desc_tx {
    color: #c4c0c0;
    font-size: 10px;
    word-break: break-all;
  }

  &:hover {
    border-color: #1677ff;
  }
}

.progress_view {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  /deep/ .el-progress__text {
    font-size: 14px !important;
    color: white !important;
    text-align: center;
  }
}
</style>../common/utils