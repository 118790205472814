
import { wechatEmojiMap } from "./icon";
// 文本类型消息 将内部表情文字 替换成表情
export const textMessageToHtml = function (text) {
  let map = wechatEmojiMap;
  let regexString = Object.keys(map).map(emoji => `(\\${emoji})`).join('|');
  let regex = new RegExp(regexString, 'g');
  text = text.replace(regex, (match) => {
    return `<img src="${require(`@/assets/emoji/${map[match]}`)}" style="width:20px;height:20px;vertical-align: middle;" data-key="${match}"/>`;
  });
  text = text.replace(/\n/g, '<br>');
  return text;
};

/**
 * 将编辑器内内容转换成message
 * @param {Element} div - 编辑器dom
 * @returns {Array} - 内容字符串
 */

export const htmlToMessage = function (div) {
  let editorNode = div.cloneNode(true);
  let emojiNodes = Array.from(editorNode.getElementsByTagName('img')).filter(node => node.getAttribute('data-key'));
  emojiNodes.forEach(node => {
    node.parentElement.replaceChild(document.createTextNode(node.getAttribute('data-key')), node);
  });
  let sumStr = '';
  let msgList = [];
  Array.from(editorNode.childNodes).forEach(node => {
    if (node.nodeType == Node.TEXT_NODE) {
      sumStr += node.nodeValue;
    } else if (node.nodeName.toLowerCase() == 'br') {
      if (sumStr.length) {
        sumStr += '\n';
      }
    } else if (node.nodeName.toLowerCase() == 'img') {
      if (sumStr.length) {
        msgList.push(sumStr.trimEnd('\n'));
      }
      sumStr = '';
      msgList.push({ type: 'image', fileUrl: node.src });
    }
  });
  if (sumStr) {
    msgList.push(sumStr.trimEnd('\n'));
  }
  return msgList;
};