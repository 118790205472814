<!-- 聊天输入框 -->
<template>
  <div class="editor_bg_view" v-show="selectUserInfo.externalUserid">
    <div v-if="selectUserInfo.isOnline == 0" class="not_online_view">许愿不在线，请查看对应企微是否登录</div>
    <div class="menu_view">
      <el-popover ref="popoverRef" placement="top-start" trigger="click">
        <emojiList @emojiClick="() => $refs.popoverRef.doClose()"></emojiList>
        <div slot="reference" class="menu_item" v-html="chatMenuIcons.emoji"></div>
      </el-popover>
      <div class="menu_item" v-html="chatMenuIcons.image" @click="$refs.imageFileInput.click()"></div>
      <div class="menu_item" v-html="chatMenuIcons.video" @click="$refs.videoFileInput.click()"></div>
      <el-popover ref="popoverRef_card" placement="top-start" trigger="click">
        <weappCard @close="() => $refs.popoverRef_card.doClose()" :selectUserInfo="selectUserInfo"></weappCard>
        <el-button slot="reference" icon="el-icon-plus" size="mini" style="margin-left: 20px;">发送卡片</el-button>
      </el-popover>
    </div>
    <div id="editor_view" class="editor_view" :contenteditable="selectUserInfo.isOnline != 0" @keydown="editorKeyDownAction" data-placeholder="请输入内容..." @paste="editorPasteAction" @click="editorClickAction" @copy="editorCopyAction"></div>
    <div class="bottom_view">
      <el-button type="primary" size="medium" @click="createMessageList">发送[Enter]</el-button>
    </div>
    <!-- 上传文件 -->
    <div style="display: none;">
      <input ref="imageFileInput" type="file" @change="chooseFileAction($event, 'image')" accept="image/jpeg, image/png, image/gif, image/jpg,">
      <input ref="videoFileInput" type="file" @change="chooseFileAction($event, 'video')" accept=".mp4, .webm, .mov">
    </div>
  </div>
</template>

<script>
import { Noti, Noti_Key } from '@/common/event';
import { chatMenuIcons } from '../common/icon';
import emojiList from './emojiList.vue';
import { htmlToMessage, textMessageToHtml } from '../common/utils';
import weappCard from './weappCard.vue';
import Trace from '@/common/trace';
export default {
  props: ['isOnline'],
  components: {
    emojiList,
    weappCard
  },
  data() {
    return {
      chatMenuIcons,
      selectUserInfo: {},
    };
  },
  inject: ['sendMessage'],
  mounted() {
    // 粘贴的图片 blob url对应file map
    this.pastedImageMap = new Object();
    // 监听会话选择
    Noti.$on(Noti_Key.Select_User, (res) => {
      this.selectUserInfo = res;
      if (!res.isSame) {
        this.clearInput();
      }
    });
    // 小必姐在线状态变化
    Noti.$on(Noti_Key.Xbj_Online_Status_Change, res => {
      if (res.data.staffId == this.selectUserInfo.xbjId) {
        this.selectUserInfo = { ...this.selectUserInfo, isOnline: res.data.status };
      }
      if (this.selectUserInfo.isOnline == 0) {
        this.clearInput();
      }
    });
    this.$nextTick(_ => {
      this.addEditorEvent();
    });
  },
  methods: {
    // 监听输入框是否中文输入中...
    addEditorEvent() {
      let editorDom = this.$el.querySelector('.editor_view');
      editorDom.addEventListener('compositionstart', () => {
        this.isComposing = true;
      });
      editorDom.addEventListener('compositionend', () => {
        this.isComposing = false;
      });
    },
    // 输入框清空
    clearInput() {
      let editorDom = this.$el.querySelector('.editor_view');
      editorDom.innerHTML = '';
    },
    // 键盘点击
    editorKeyDownAction(e) {
      if (e.key === 'Enter') {
        if (this.isComposing) {
          return;
        }
        e.preventDefault();
        if (e.ctrlKey || e.shiftKey) {
          document.execCommand('insertLineBreak');
          return;
        }
        this.createMessageList();
      }
    },
    // 编辑器内元素点击
    editorClickAction(e) {
      let targetNode = e.target;
      if (targetNode.nodeName.toLowerCase() === 'img') {
        // 如果是点击的图片  将光标放到图片后面
        this.setRangeAction(targetNode);
      }
    },
    // 设置选区在此dom
    setRangeAction(dom) {
      var range = document.createRange();
      range.setStartAfter(dom);
      range.setEndAfter(dom);
      var selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    },
    // 输入框粘贴数据 触发
    editorPasteAction(e) {
      e.preventDefault();
      var clipboardData = (e.clipboardData || window.clipboardData);
      let pastedHtml = clipboardData.getData('text/html');
      var pastedText = clipboardData.getData('text/plain');
      // 判断是否文本
      if (pastedText) {
        pastedText = pastedText.replace(/<[^>]*>/g, '');
        pastedText = pastedText.replace(/\r/g, '');
        pastedText = pastedText.replace(/\n/g, '<br>');
        pastedText = textMessageToHtml(pastedText);
        document.execCommand('insertHTML', false, pastedText);
      }
      // 图片粘贴处理
      let isHaveErrorImg = false;
      let imgHtml = '';
      for (let i = 0; i < clipboardData.items.length; i++) {
        const item = clipboardData.items[i];
        // 判断项的类型是否为图片
        if (item.type.startsWith('image/')) {
          if (!['image/jpeg', 'image/png', 'image/gif', 'image/jpg'].includes(item.type)) {
            isHaveErrorImg = true;
            continue;
          }
          const file = item.getAsFile();
          if (file) {
            let blobUrl = window.URL.createObjectURL(file);
            imgHtml += `<img style="max-width: 100px;max-height: 100px; min-height: 20px; min-width: 20px;" src="${blobUrl}" />`;
            this.pastedImageMap[blobUrl] = file;
          }
        }
      }
      if (isHaveErrorImg) {
        this.$message.warning('图片类型不支持，仅支持jpeg/png/gif/jpg格式图片！');
      }
      if (imgHtml) {
        document.execCommand('insertHTML', false, imgHtml);
      } else if (pastedHtml && !imgHtml) {
        // 判断是否复制的图文 
        var imgRegex = /<img\b[^>]*>/gi;
        if (imgRegex.test(pastedHtml)) {
          this.$message.warning('图片粘贴失败，请单独选择图片再进行复制粘贴');
        }
      }
    },
    // 监听聊天输入框拷贝
    editorCopyAction(event) {
      event.preventDefault();
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const selectedNodes = Array.from(range.cloneContents().childNodes);
        let clipboardText = '';
        for (const node of selectedNodes) {
          if (node.nodeType == Node.TEXT_NODE) {
            clipboardText += node.textContent;
          } else if (node.nodeName.toLowerCase() == 'br') {
            clipboardText += '\n';
          } else if (node.nodeName.toLowerCase() == 'img' && node.getAttribute('data-key')) {
            clipboardText += node.getAttribute('data-key');
          }
        }
        // 将转换后的文本写入剪贴板
        if (event.clipboardData.setData) {
          event.clipboardData.setData('text/plain', clipboardText);
        } else if (window.clipboardData) { // 对于老版本IE
          window.clipboardData.setData('Text', clipboardText);
        }
      }
    },
    // 创建消息数据
    createMessageList() {
      let editorDom = this.$el.querySelector('.editor_view');
      let isEmpty = editorDom.textContent.trim().length == 0 && editorDom.getElementsByTagName('img').length == 0;
      Trace({
        operationType: 12,
        extraData: JSON.stringify({ isEmpty: isEmpty ? 1 : 0 })
      });
      if (isEmpty) {
        this.$message.warning('不能发送空消息!');
        return;
      }
      // 发送消息逻辑...
      let messageList = htmlToMessage(editorDom);
      messageList = messageList.filter(item => {
        if (item.type == 'image') {
          if (!this.pastedImageMap[item.fileUrl]) {
            return false;
          }
          item.file = this.pastedImageMap[item.fileUrl];
          delete this.pastedImageMap[item.fileUrl];
        }
        return true;
      });
      this.sendMessage(messageList);
      this.clearInput();
    },
    // 选择文件 1图片 2视频
    chooseFileAction(event, type) {
      let file = event.target.files[0];
      let fileUrl = window.URL.createObjectURL(file);
      this.sendMessage([{ type, file, fileUrl }]);
      if (event.target.value) {
        event.target.value = '';
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.editor_bg_view {
  height: 260px;
  border-top: 2px solid var(--boder-color);
  width: 100%;
  box-sizing: border-box;
  position: relative;


  .menu_view {
    height: 40px;
    border-bottom: 1px solid var(--boder-color);
    display: flex;
    flex-direction: row;
    align-items: center;

    .menu_item {
      padding-left: 20px;
      cursor: pointer;

      /deep/ path {
        fill: #666;
      }

      &:hover {
        /deep/ path {
          fill: #409EFF;
        }
      }
    }
  }

  .editor_view {
    height: calc(100% - 120px);
    padding: 10px;
    border: none;
    outline: none;
    overflow-y: auto;
    font-size: 14px;
    white-space: normal;
    word-break: break-all;
    overflow-x: hidden;
    line-height: 20px;
    vertical-align: middle;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;

    &[contenteditable]:empty:before {
      content: attr(data-placeholder);
      color: #aaa;
      display: block;
      pointer-events: none;
    }
  }

  .bottom_view {
    display: flex;
    justify-content: flex-end;
    padding: 10px 30px 0;
    border-top: 2px solid var(--boder-color);
  }

  .not_online_view {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.4);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>../../../common/icon../../../common/utils@/pages/chat/components/contentView/common/icon@/pages/chat/components/contentView/common/utils