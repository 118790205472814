<template>
  <div class="emoji_list_view">
    <div class="emoji_item_view" v-for="item in wechatImageList" :key="item.key" @click="clickAction(item)">
      <img :src="require(`@/assets/emoji/${item.url}`)" alt="0">
    </div>
    <div class="line_view"></div>
    <div class="emoji_item_view" v-for="(item, index) in wechatEmojiTextList" :key="`key_${index}`" @click="clickAction(item)">
      {{ item }}
    </div>
  </div>
</template>

<script>
import { wechatEmojiMap, wechatEmojiTextList } from '../common/icon';
import { textMessageToHtml } from '../common/utils';
export default {
  data() {
    return {
      wechatImageList: [],
      wechatEmojiTextList: [],
      showEmojiPageIndex: 1
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let list = [];
      for (const key in wechatEmojiMap) {
        list.push({
          type: 'wechat',
          key,
          url: wechatEmojiMap[key],
          index: parseInt(wechatEmojiMap[key].split("_")[0])
        });
      }
      list.sort((a, b) => {
        return a.index - b.index;
      });
      this.wechatImageList = list;
      this.wechatEmojiTextList = wechatEmojiTextList;
    },
    // 获取表情对应的Node节点
    getEmojiNode(data) {
      if (data.type) {
        let imgHtml = textMessageToHtml(data.key);
        let parse = new DOMParser();
        let imgNode = parse.parseFromString(imgHtml, 'text/html').body.firstChild;
        parse = null;
        return imgNode;
      } else {
        // 创建一个新的文本节点
        var textNode = document.createTextNode(data);
        return textNode;
      }
    },
    // 表情点击
    clickAction(item) {
      if (!window.getSelection()) {
        this.$message.error('该浏览器不支持表情选择，请更换为谷歌浏览器！');
        return;
      }
      let editorDom = document.getElementById('editor_view');
      let selection = window.getSelection();
      let emojiNode = this.getEmojiNode(item);
      let range = null;
      // 输入框处理 
      if (!editorDom.lastChild || !selection.anchorNode || !editorDom.contains(selection.anchorNode)) {
        // 为空/此时无选区/此时选区不在输入框内
        editorDom.appendChild(emojiNode);
        range = document.createRange();
      } else {
        range = selection.getRangeAt(0);
        range.insertNode(emojiNode);
      }
      range.setStartAfter(emojiNode);
      range.setEndAfter(emojiNode);
      selection.removeAllRanges();
      selection.addRange(range);
      editorDom.focus();
      this.$emit('emojiClick')
    }
  }
};
</script>

<style lang="scss" scoped>
.emoji_list_view {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  max-height: 300px;
  overflow-y: auto;

  .emoji_item_view {
    width: 25px;
    height: 25px;
    border: 1px solid #fafafa;
    cursor: pointer;
    user-select: none;
    font-size: 20px;
    line-height: 25px;
    text-align: center;

    img {
      font-size: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  .line_view {
    width: 100%;
    height: 2px;
    background-color: #ccc;
    margin: 10px;
  }
}
</style>