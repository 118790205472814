import { Noti, Noti_Key } from "../../../common/event";
import { MessageBox } from 'element-ui';
class BYWebSocket {
  #webManager;
  #taskTimeout = null;
  #socketUrl = '';
  #errMsg = null;
  #isDestroyed = false;
  status = '';
  constructor(url) {
    this.#socketUrl = url;
    this.init();
  }
  init() {
    let ws = new WebSocket(this.#socketUrl);
    this.#webManager = ws;
    ws.addEventListener('open', () => this.onOpen());
    ws.addEventListener('close', () => this.close());
    ws.addEventListener('error', () => this.onError());
    ws.addEventListener('message', (res) => this.onMessage(res.data));
  }
  onOpen() {
    console.info("---socket连接成功--", this.#webManager);
    this.rubTaskStart();
    this.status = 'open';
  }
  send(data) {
    this.#webManager.send(data);
  }
  // 心跳任务
  rubTaskStart() {
    this.#taskTimeout = setTimeout(() => {
      this.#webManager.send('PING');
      this.rubTaskStart();
    }, 10000);
  }
  onMessage(data) {
    try {
      let obj = JSON.parse(data);
      if (obj.type == 'change') {
        // 会话联系人整体状态 发生变化
        Noti.$emit(Noti_Key.User_Change, obj);
      } else if (obj.type == 'delete') {
        // 会话联系人被删除
        Noti.$emit(Noti_Key.User_Delete, obj);
      } else if (obj.type == 'xbjOnlineStatusChange') {
        // 小必姐在线状态 发生变化
        Noti.$emit(Noti_Key.Xbj_Online_Status_Change, obj);
      } else if (obj.type == 'messageStatusChange') {
        // 会话中的消息 发生变化
        Noti.$emit(Noti_Key.Message_Status_Change, obj);
      }
    } catch (error) {
      console.error("消息错误---", data);
    }
  }
  onError() {

  }
  /**
   * 停止socket
   */
  stop() {
    this.#isDestroyed = true;
    this.close();
  }
  close() {
    this.#webManager.close();
    this.#taskTimeout && clearTimeout(this.#taskTimeout);
    this.#taskTimeout = null;
    this.status = 'close';
    if (!this.#isDestroyed) {
      MessageBox.alert('实时消息连接断开，是否重新连接！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false,
        showCancelButton: true,
      }).then(() => {
        this.init();
      }).catch(_ => {
        window.location.href = 'http://ai.biyao.com';
      });
    }
  }
}

export default BYWebSocket;