<!-- 消息列表区域 -->
<template>
  <div class="content_view" v-loading="showLoading">
    <div class="title_view">
      <span class="name_tx">{{ selectUserInfo.userName || '【请选择会话】' }}</span>
      <!-- <el-button :loading="deleteBtnLoading" size="small" :disabled="!selectUserInfo.externalUserid" type="primary" @click="deleteMsgAction">无需回复</el-button> -->
    </div>
    <messageList :dataList="dataList" ref="messageList"></messageList>
    <chatInput></chatInput>
  </div>
</template>

<script>
import { Noti, Noti_Key } from '@/common/event';
import messageList from './components/messageList.vue';
import chatInput from './components/chatInput.vue';
import moment from 'moment';
import API from '@/common/axios';
import * as UUID from 'uuid';
import Trace from '@/common/trace';
export default {
  components: {
    messageList,
    chatInput
  },
  data() {
    return {
      selectUserInfo: {},
      dataList: [],
      deleteBtnLoading: false,
      showLoading: false
    };
  },
  provide() {
    return {
      sendMessage: this.sendMessage,
    };
  },
  mounted() {
    // 上传文件 任务队列
    this.uploadTaskMap = new Object();
    // 监听会话数据变化
    Noti.$on(Noti_Key.Select_User, (res) => {
      this.selectUserInfo = res;
      this.getMessageList();
    });
    // 消息状态发生变化 处理消息状态
    Noti.$on(Noti_Key.Message_Status_Change, res => {
      if (this.selectUserInfo.xbjId != res.data.staffId || this.selectUserInfo.externalUserid != res.data.externalUserId) {
        return;
      }
      let obj = this.dataList.find(item => item.msgUuid == res.data.msgUuid);
      if (obj) {
        obj.status = res.data.status;
      }
    });
  },
  destroyed() {
    this.stopAllUplodaTask();
  },
  methods: {
    // 获取消息列表
    getMessageList() {
      let waiteMsgMap = this.dataList.reduce(function (map, item) {
        if (item.status && item.msgUuid && item.status != 'success') {
          map[item.msgUuid] = JSON.parse(JSON.stringify(item));
        }
        return map;
      }, {});
      if (!this.selectUserInfo.isSame) {
        this.showLoading = true;
        this.dataList = [];
        // 会话对象发生变化 取消当前所有上传队列
        this.stopAllUplodaTask();
      }
      this.deleteBtnLoading = false;
      if (this.cancelRequestToken) {
        this.cancelRequestToken();
        this.cancelRequestToken = null;
      }
      let { xbjId, externalUserid } = this.selectUserInfo;
      if (!externalUserid) {
        this.showLoading = false;
        return;
      };
      let params = {
        xbjId,
        externalUserid
      };
      API({
        url: '/pendingreply/getChatHistoryByCondtion.do',
        data: params,
        cancelToken: new API.CancelToken(c => this.cancelRequestToken = c)
      }).then((res) => {
        this.showLoading = false;
        if (res.success == 0) {
          this.$message.error(res.errMsg);
          return;
        }
        if (!this.selectUserInfo.isSame) {
          this.dataList = res.data;
          this.$refs.messageList.init();
        } else {
          let list = res.data;
          list.forEach((item) => {
            if (item.msgUuid && waiteMsgMap[item.msgUuid]) {
              delete waiteMsgMap[item.msgUuid];
            }
          });
          list.push(...Object.values(waiteMsgMap));
          list.sort((a, b) => {
            return a.msgTime - b.msgTime;
          });
          this.dataList = list;
        }
      }).catch(error => {
        if (!API.isCancel(error)) {
          this.showLoading = false;
        }
      }).finally(_ => {
        this.cancelRequestToken = null;
      });
    },
    // 无需回复
    deleteMsgAction() {
      if (this.deleteBtnLoading) {
        return;
      }
      this.deleteBtnLoading = true;
      let { xbjId, externalUserid } = this.selectUserInfo;
      let params = { xbjId, externalUserid };
      let obj = this.dataList.slice().reverse().find((item) => item.isUser == 1);
      if (obj) {
        params.seq = obj.seq;
        params.msgTime = obj.msgTime;
        params.qyMsgId = obj.qyMsgId;
        params.msgId = obj.msgId;
      }
      API({
        url: '/pendingreply/markNoReply.do',
        data: params
      }).then(res => {
        if (res.success != 1) {
          this.$message.error(res.errMsg);
          return;
        }
        Trace({
          operationType: 6,
        });
        // Noti.$emit(Noti_Key.User_Delete, { xbjId, data: { ...this.selectUserInfo }, isButton: true });
      }).finally(() => this.deleteBtnLoading = false);
    },
    // 消息发送
    async sendMessage(list) {
      if (!Array.isArray(list)) {
        console.error('消息格式为数组，不正确');
        return;
      }
      const res = await API({
        url: '/xbj/status.do',
        data: {
          xbjId: this.selectUserInfo.xbjId
        },
      });
      if (res.data != 1) {
        this.$message.warning('许愿不在线，请查看对应企微是否登录');
        Noti.$emit(Noti_Key.Xbj_Online_Status_Change, { data: { staffId: this.selectUserInfo.xbjId, status: 0 } });
        return;
      }
      let socketMessageVo = {
        data: {
          externalUserId: this.selectUserInfo.externalUserid,
          staffId: this.selectUserInfo.xbjId
        },
        type: 'sendMessage'
      };
      let msgList = list.reduce((arr, item, i) => {
        let messageVo = {
          isUser: 0,
          name: this.selectUserInfo.xbjNickName,
          time: moment().format('MM-DD HH:mm:ss'),
          status: 'loading',
          msgUuid: UUID.v4(),
          msgTime: new Date().getTime(),
          msgObj: {
            type: -1,
            messageText: "",
            imgUrl: "",
            progressNum: 0,
          }
        };
        if (item.type == 'image') {
          messageVo.msgObj.type = 2;
          messageVo.msgObj.imgUrl = item.fileUrl;
          socketMessageVo.data.messageItemList = [{ msgType: 'image', msgUuid: messageVo.msgUuid }];
          this.fileMessageUpLoad(item.file, item.fileUrl, JSON.parse(JSON.stringify(socketMessageVo)));
        } else if (item.type == 'video') {
          messageVo.msgObj.type = 5;
          messageVo.msgObj.videoUrl = item.fileUrl;
          socketMessageVo.data.messageItemList = [{ msgType: 'video', msgUuid: messageVo.msgUuid }];
          this.fileMessageUpLoad(item.file, item.fileUrl, JSON.parse(JSON.stringify(socketMessageVo)));
        } else if (item.type == 'miniprogram') {
          messageVo.msgObj.type = 6;
          messageVo.msgObj.miniProgrameTitle = item.miniProgrameTitle;
          messageVo.msgObj.miniProgrameName = item.miniProgrameName;
          socketMessageVo.data.messageItemList = [{ msgType: "weapp", appPath: item.path, appTitle: item.miniProgrameTitle, appImg: item.imgUrl, msgUuid: messageVo.msgUuid, displayName: item.miniProgrameName }];
          this.$emit('sendMessage', JSON.stringify(socketMessageVo));
        } else if (item.trim().length != 0) {
          messageVo.msgObj.type = 1;
          messageVo.msgObj.messageText = item;
          socketMessageVo.data.messageItemList = [{ msgContent: item, msgType: 'text', msgUuid: messageVo.msgUuid }];
          this.$emit('sendMessage', JSON.stringify(socketMessageVo));
        }
        arr.push(messageVo);
        return arr;
      }, []);
      if (msgList.length) {
        this.dataList.push(...msgList);
        this.$refs.messageList.init();
      }
    },
    // 视频 图片上传 发送消息
    fileMessageUpLoad(file, fileUrl, socketMessageVo) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append("type", "oss");
      API({
        url: 'http://aiapi.biyao.com/s3/upload',
        data: formData,
        withCredentials: false,
        cancelToken: new API.CancelToken(c => {
          this.uploadTaskMap[fileUrl] = c;
        }),
        onUploadProgress: (progressEvent) => {
          let progressNum = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          let obj = this.dataList.find(ob => ob.msgObj.imgUrl == fileUrl || ob.msgObj.videoUrl == fileUrl);
          obj.msgObj.progressNum = progressNum >= 100 ? 0 : progressNum;
        }
      }).then(res => {
        if (res.code != 200) {
          throw '';
        }
        socketMessageVo.data.messageItemList[0].msgContent = res.data;
        this.$emit('sendMessage', JSON.stringify(socketMessageVo));
      }).catch(error => {
        if (!API.isCancel(error)) {
          // 文件上传失败情况下  把消息状态变成fail
          let obj = this.dataList.find(ob => ob.msgObj.imgUrl == fileUrl || ob.msgObj.videoUrl == fileUrl);
          obj.status = 'fail';
        }
      }).finally(_ => {
        this.uploadTaskMap[fileUrl] && delete this.uploadTaskMap[fileUrl];
      });
    },
    // 切换会话停止当前所有 图片 视频发送任务
    stopAllUplodaTask() {
      for (const key in this.uploadTaskMap) {
        let fn = this.uploadTaskMap[key];
        if (fn) {
          fn();
          delete this.uploadTaskMap[key];
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content_view {
  position: absolute;
  left: 300px;
  top: 60px;
  bottom: 0;
  box-sizing: border-box;
  width: calc((100% - 300px) / 2 + 100px);

  .title_view {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid var(--boder-color);
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0 10px;

    .name_tx {
      max-width: 50%;
      font-size: 14px;
      color: #333;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>