export const chatMenuIcons = {
  emoji: '<svg t="1706249024582" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13183" width="24" height="24"><path d="M510.464 94.208C265.728 94.208 66.56 293.376 66.56 538.112s199.168 443.904 443.904 443.904 443.904-199.168 443.904-443.904S755.2 94.208 510.464 94.208z m0 836.608C293.888 930.816 117.76 754.688 117.76 538.112s176.128-392.704 392.704-392.704 392.704 176.128 392.704 392.704S727.04 930.816 510.464 930.816z" fill="#333333" p-id="13184"></path><path d="M677.12 630.528c-11.264-8.448-27.392-6.144-35.84 5.12-31.104 41.6-78.848 65.536-130.688 65.536-52.096 0-99.84-24.064-130.944-65.92a25.5616 25.5616 0 0 0-35.84-5.248c-11.392 8.448-13.696 24.448-5.248 35.84 40.32 54.144 104.576 86.528 172.032 86.528 68.224 0 130.816-31.36 171.776-86.016 8.448-11.264 6.144-27.392-5.248-35.84zM367.104 527.232c14.08 0 25.6-11.52 25.6-25.6V441.6c0-14.08-11.52-25.6-25.6-25.6s-25.6 11.52-25.6 25.6v60.032a25.6 25.6 0 0 0 25.6 25.6zM626.944 527.232c14.08 0 25.6-11.52 25.6-25.6V441.6c0-14.08-11.52-25.6-25.6-25.6s-25.6 11.52-25.6 25.6v60.032a25.6 25.6 0 0 0 25.6 25.6z" fill="#333333" p-id="13185"></path></svg>',
  image: '<svg t="1706248940679" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10424" width="23" height="23"><path d="M890.98 51.46H133.06c-45.04 0-81.71 36.66-81.71 81.71V891.1c0 45.04 36.66 81.71 81.71 81.71h757.93c45.04 0 81.71-36.66 81.71-81.71V133.16c-0.01-45.04-36.67-81.7-81.72-81.7z m0 864.5H133.06c-13.71 0-24.87-11.16-24.87-24.87V133.16c0-13.71 11.16-24.87 24.87-24.87h757.93c13.71 0 24.87 11.16 24.87 24.87v492.1L772.97 467.21c-14.99-16.62-36.41-26.42-58.81-26.89-22.54 0.11-44.27 8.41-59.95 24.42L455.03 667.59c-9.38 9.49-24.45 9.96-34.3 0.97l-68.22-62.14c-29.59-26.98-73.96-28.59-105.46-3.75-91.53 72.22-92.31 73.44-95.64 78.46-8.6 13.13-4.97 30.75 8.13 39.38 11.63 7.63 26.7 5.66 36.02-3.94 4.83-4.25 29.84-24.42 86.67-69.27 9.52-7.49 23.01-7.05 32 1.14l68.22 62.17c15.68 14.27 35.33 21.34 54.92 21.34 21.15 0 42.21-8.24 58.23-24.56l199.19-202.82c4.8-4.94 12.13-8.69 18.18-7.41 6.88 0.14 13.21 3.03 17.82 8.16l158.56 175.43c6.91 7.64 17.06 10.64 26.5 8.83V891.1c0 13.7-11.16 24.86-24.87 24.86z" p-id="10425"></path><path d="M350.7 472.79c65.53 0 118.84-53.31 118.84-118.84S416.23 235.13 350.7 235.13s-118.81 53.29-118.81 118.81 53.28 118.85 118.81 118.85z m0-180.82c34.19 0 62 27.81 62 61.97 0 34.19-27.81 62-62 62-34.16 0-61.97-27.81-61.97-62 0-34.16 27.81-61.97 61.97-61.97zM824.21 642.14c-10.77-11.43-28.81-11.88-40.19-1.11-11.38 10.77-11.91 28.78-1.11 40.19l18.79 19.87c5.58 5.91 13.1 8.88 20.65 8.88 7.02 0 14.04-2.58 19.54-7.77 11.38-10.77 11.91-28.78 1.11-40.19l-18.79-19.87zM777.16 639.03c10.74-11.43 10.19-29.42-1.25-40.16l-35.64-33.5c-11.07-10.46-28.48-10.24-39.33 0.39L578.39 685.74c-11.21 10.99-11.41 28.97-0.44 40.19a28.35 28.35 0 0 0 20.32 8.55c7.16 0 14.35-2.69 19.87-8.1l103.08-100.91L737 640.28c11.38 10.74 29.39 10.24 40.16-1.25z" p-id="10426"></path></svg>',
  video: '<svg t="1706248837089" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7045" width="24" height="24"><path d="M793.6 972.8l-563.2 0c-113.1008 0-204.8-91.6992-204.8-204.8L25.6 256c0-113.1008 91.6992-204.8 204.8-204.8l563.2 0c113.1008 0 204.8 91.6992 204.8 204.8l0 512C998.4 881.1008 906.7008 972.8 793.6 972.8zM947.2 286.72c0-101.7856-82.5344-184.32-184.32-184.32L261.12 102.4C159.3344 102.4 76.8 184.9344 76.8 286.72l0 450.56c0 101.7856 82.5344 184.32 184.32 184.32l501.76 0c101.7856 0 184.32-82.5344 184.32-184.32L947.2 286.72zM384 308.224l307.2 203.776-307.2 203.776L384 308.224z" fill="#272636" p-id="7046"></path></svg>'
};

export const wechatEmojiMap = {
  '[微笑]': '001_微笑.png',
  '[鼓掌]': '038_鼓掌.png',
  '[裂开]': '075_裂开.png',
  '[撇嘴]': '002_撇嘴.png',
  '[坏笑]': '039_坏笑.png',
  '[嘴唇]': '076_嘴唇.png',
  '[色]': '003_色.png',
  '[右哼哼]': '040_右哼哼.png',
  '[爱心]': '077_爱心.png',
  '[发呆]': '004_发呆.png',
  '[鄙视]': '041_鄙视.png',
  '[心碎]': '078_心碎.png',
  '[得意]': '005_得意.png',
  '[委屈]': '042_委屈.png',
  '[拥抱]': '079_拥抱.png',
  '[流泪]': '006_流泪.png',
  '[快哭了]': '043_快哭了.png',
  '[强]': '080_强.png',
  '[害羞]': '007_害羞.png',
  '[阴险]': '044_阴险.png',
  '[弱]': '081_弱.png',
  '[闭嘴]': '008_闭嘴.png',
  '[亲亲]': '045_亲亲.png',
  '[握手]': '082_握手.png',
  '[睡]': '009_睡.png',
  '[可怜]': '046_可怜.png',
  '[胜利]': '083_胜利.png',
  '[大哭]': '010_大哭.png',
  '[笑脸]': '047_笑脸.png',
  '[抱拳]': '084_抱拳.png',
  '[尴尬]': '011_尴尬.png',
  '[生病]': '048_生病.png',
  '[勾引]': '085_勾引.png',
  '[发怒]': '012_发怒.png',
  '[脸红]': '049_脸红.png',
  '[拳头]': '086_拳头.png',
  '[调皮]': '013_调皮.png',
  '[破涕为笑]': '050_破涕为笑.png',
  '[OK]': '087_OK.png',
  '[呲牙]': '014_呲牙.png',
  '[恐惧]': '051_恐惧.png',
  '[合十]': '088_合十.png',
  '[惊讶]': '015_惊讶.png',
  '[失望]': '052_失望.png',
  '[啤酒]': '089_啤酒.png',
  '[难过]': '016_难过.png',
  '[无语]': '053_无语.png',
  '[咖啡]': '090_咖啡.png',
  '[囧]': '017_囧.png',
  '[嘿哈]': '054_嘿哈.png',
  '[蛋糕]': '091_蛋糕.png',
  '[抓狂]': '018_抓狂.png',
  '[捂脸]': '055_捂脸.png',
  '[玫瑰]': '092_玫瑰.png',
  '[吐]': '019_吐.png',
  '[奸笑]': '056_奸笑.png',
  '[凋谢]': '093_凋谢.png',
  '[偷笑]': '020_偷笑.png',
  '[机智]': '057_机智.png',
  '[菜刀]': '094_菜刀.png',
  '[愉快]': '021_愉快.png',
  '[皱眉]': '058_皱眉.png',
  '[炸弹]': '095_炸弹.png',
  '[白眼]': '022_白眼.png',
  '[耶]': '059_耶.png',
  '[便便]': '096_便便.png',
  '[傲慢]': '023_傲慢.png',
  '[吃瓜]': '060_吃瓜.png',
  '[月亮]': '097_月亮.png',
  '[困]': '024_困.png',
  '[加油]': '061_加油.png',
  '[太阳]': '098_太阳.png',
  '[惊恐]': '025_惊恐.png',
  '[汗]': '062_汗.png',
  '[庆祝]': '099_庆祝.png',
  '[憨笑]': '026_憨笑.png',
  '[天啊]': '063_天啊.png',
  '[礼物]': '100_礼物.png',
  '[悠闲]': '027_悠闲.png',
  '[Emm]': '064_Emm.png',
  '[红包]': '101_红包.png',
  '[咒骂]': '028_咒骂.png',
  '[社会社会]': '065_社会社会.png',
  '[發]': '102_發.png',
  '[疑问]': '029_疑问.png',
  '[旺柴]': '066_旺柴.png',
  '[福]': '103_福.png',
  '[嘘]': '030_嘘.png',
  '[好的]': '067_好的.png',
  '[烟花]': '104_烟花.png',
  '[晕]': '031_晕.png',
  '[打脸]': '068_打脸.png',
  '[爆竹]': '105_爆竹.png',
  '[衰]': '032_衰.png',
  '[哇]': '069_哇.png',
  '[猪头]': '106_猪头.png',
  '[骷髅]': '033_骷髅.png',
  '[翻白眼]': '070_翻白眼.png',
  '[跳跳]': '107_跳跳.png',
  '[敲打]': '034_敲打.png',
  '[666]': '071_666.png',
  '[发抖]': '108_发抖.png',
  '[再见]': '035_再见.png',
  '[让我看看]': '072_让我看看.png',
  '[转圈]': '109_转圈.png',
  '[擦汗]': '036_擦汗.png',
  '[叹气]': '073_叹气.png',
  '[抠鼻]': '037_抠鼻.png',
  '[苦涩]': '074_苦涩.png'
};
export const wechatEmojiTextList = [
  '😄', '😃', '😀', '😊', '😉', '😍', '😘', '😚', '😳',
  '😌', '😁', '😜', '😝', '😒', '😒', '😏', '😓', '😔',
  '😞', '😖', '😥', '😰', '😨', '😣', '😢', '😭', '😂',
  '😲', '😱', '😠', '😡', '😪', '😷', '👿', '👽', '💛',
  '💙', '💜', '💗', '💚', '💔', '💓', '💘', '✨', '🌟',
  '💢', '💤', '💨', '💦', '🎶', '🎵', '🔥', '💩', '👍',
  '👎', '👌', '👊', '✊', '👋', '👐', '👆', '👇', '👉',
  '👈', '🙌', '🙏', '👏', "💪", ""
];
