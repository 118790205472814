<!-- 企微web区域 -->
<template>
  <div class="web_content_view">
    <div class="menu_list_view">
      <div>
        <el-button style="margin: 10px 0 0 0" v-for="(item, index) in menuList" :key="item.url" :disabled="isDisable(item)" :type="index == selectButtonIndex ? 'primary' : 'default'" @click="menuAction(index)" size="medium">
          {{ item.name }}
        </el-button>
      </div>
    </div>
    <div v-if="selectButtonIndex == -1" class="web_iframe_view">
      <span style="color: #666;">暂无数据</span>
    </div>
    <!-- 用户信息组件 -->
    <div class="web_iframe_view" v-else-if="menuList[selectButtonIndex].type == 'userInfo'">
      <userInfoView :selectUserInfo="selectUserInfo" :key="`${selectUserInfo.externalUserid}_userInfoView`"></userInfoView>
    </div>
    <!-- iframe -->
    <iframe frameborder="0" :key="selectWebUrl" v-else-if="menuList[selectButtonIndex].url" class="web_iframe_view" :style="menuList[selectButtonIndex].style || ''" :src="selectWebUrl"></iframe>
    <!-- 朋友圈组件 -->
    <div class="web_iframe_view" v-else-if="menuList[selectButtonIndex].type == 'friend'">
      <friendView :selectUserInfo="selectUserInfo" :key="`${selectUserInfo.externalUserid}_friendView`"></friendView>
    </div>
    <!-- 代下单组件 -->
    <div class="web_iframe_view" v-else-if="menuList[selectButtonIndex].type == 'createOrder'">
      <createOrder :uid="uid" :suId="suId" :selectUserInfo="selectUserInfo" :key="`${selectUserInfo.externalUserid}_${suId}_userInfoView`"></createOrder>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import { Noti, Noti_Key } from '@/common/event';
import API from '@/common/axios';
import Trace from '@/common/trace';
import jsCookie from 'js-cookie';
export default {
  data() {
    return {
      selectButtonIndex: -1,
      menuList: [
        { name: '用户信息', type: 'userInfo', operationType: 1 },
        { name: '订单管理', url: 'https://h5.biyao.com/byManager/orderList', type: 'h5', operationType: 2, style: "width: 420px" },
        { name: '客户收藏', url: 'https://h5.biyao.com/byManager/collection', type: 'h5', operationType: 3, style: "width: 420px" },
        { name: '津贴账户', url: 'https://h5.biyao.com/byManager/denomination', type: 'h5', operationType: 4, style: "width: 420px" },
        { name: '用户搜索', url: 'https://h5.biyao.com/byManager/userSearch', type: 'h5' },
        { name: '朋友圈', type: 'friend', operationType: 7 },
        { name: '单品会话', url: `http://ai.biyao.com?x-ai-sk=${jsCookie.get("x-ai-sk")}`, type: 'ai', operationType: 5 },
        { name: '代下单', type: 'createOrder', operationType: 10 }
      ],
      selectUserInfo: {},
      uid: '',
      selectWebUrl: '',
      suId: ""
    };
  },
  components: {
    friendView: () => import('./components/friendView.vue'),
    userInfoView: () => import('./components/userInfo/index.vue'),
    createOrder: () => import('./components/createOrder/index.vue')
  },
  computed: {
    isDisable() {
      return function (item) {
        if (!this.uid && item.type == 'h5') {
          return true;
        } else if (!this.selectUserInfo.xbjId) {
          return true;
        }
      };
    }
  },
  mounted() {
    Noti.$on(Noti_Key.Select_User, (res) => {
      if (res.isSame) {
        return;
      }
      this.selectButtonIndex = res.externalUserid ? 0 : -1;
      this.selectUserInfo = res;
      this.getUserIdByExternal();
    });
    window.addEventListener('message', this.iframeMessageChangeAction);
  },
  destroyed() {
    window.removeEventListener('message', this.iframeMessageChangeAction);
  },
  methods: {
    getUserIdByExternal() {
      let { unionId } = this.selectUserInfo;
      this.uid = '';
      this.suId = '';
      if (this.cancelRequestToken) this.cancelRequestToken();
      if (!unionId) {
        return;
      }
      API({
        url: "/pendingreply/getUserInfo.do",
        data: { unionId },
        cancelToken: new API.CancelToken(c => this.cancelRequestToken = c)
      }).then(res => {
        if (res.success == 0) {
          this.$message.error(res.errMsg);
          return;
        }
        // this.selectUserInfo.unionId = 'o2Z4ds-KS3SCmkvF4e6AiGo0uPM0';
        // res.data.uid = 143727794;
        if (res.data.uid) {
          this.uid = res.data.uid;
        }
        this.selectWebUrl = this.getIframeUrl();
      }).finally(_ => {
        this.cancelRequestToken = null;
      });
    },
    menuAction(index) {
      if (index == this.selectButtonIndex) return;
      this.selectButtonIndex = index;
      this.selectWebUrl = this.getIframeUrl();
      if (!this.menuList[index].operationType) return;
      Trace({
        operationType: this.menuList[index].operationType,
      });
    },
    // iframe消息接收处理
    iframeMessageChangeAction(e) {
      if (e.origin != window.location.origin) {
        let { text, suId } = e.data;
        if (text) {
          const editorDom = document.getElementById("editor_view");
          if (editorDom && editorDom.contentEditable) {
            editorDom.focus();
            setTimeout(() => {
              document.execCommand('insertHTML', false, text);
            });
          }
        } else if (suId) {
          if (this.$route.query.debug == 1) {
            this.$prompt('输入skuId', '当前debug模式', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(({ value }) => {
              if (value) {
                this.suId = value;
                this.menuAction(7);
              }
            })
            return;
          }
          this.suId = suId;
          this.menuAction(7);
        }
      }
    },
    getIframeUrl() {
      if (this.selectButtonIndex == -1) {
        return '';
      }
      let obj = this.menuList[this.selectButtonIndex];
      let url = obj.url || '';
      if (obj.type == 'h5') {
        url += `?uid=${this.uid}&unionId=${this.selectUserInfo.unionId}&isSyProject=1&agentId=${1000024}`;
      } else if (obj.type == 'ai') {
        url += '&isChatIframe=1';
      }
      return url;
    },
    openFriendAction() {
      if (this.selectButtonIndex != 5) {
        this.selectButtonIndex = 5;
        this.selectWebUrl = '';
      }
    },
    openSopMsgAction(agentId) {
      this.selectButtonIndex = 6;
      let url = this.getIframeUrl();
      url += `&agentId=${agentId}&appId=1`;
      this.selectWebUrl = url;
    },
    openWeAppAcion(title) {
      this.selectButtonIndex = 6;
      let url = this.getIframeUrl();
      url += `&weappTitle=${title}&appId=1`;
      this.selectWebUrl = url;
    }
  }
};
</script>

<style lang="scss" scoped>
.web_content_view {
  position: absolute;
  right: 0;
  top: 60px;
  bottom: 0;
  box-sizing: border-box;
  width: calc((100% - 300px) / 2 - 100px);
  border-left: 1px solid var(--boder-color);
  display: flex;
  flex-direction: column;

  .menu_list_view {
    display: flex;
    justify-content: center;
    max-width: 100%;

    /deep/ .el-button {
      margin-left: 10px !important;
    }
  }

  .web_iframe_view {
    width: calc(100% - 20px);
    flex: 1;
    border: 1px solid var(--boder-color);
    transform-origin: center;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 15px auto 10px;
    overflow-y: auto;
    position: relative;
  }

}
</style>