<!-- 小必姐联系人列表 -->
<template>
  <div class="list_view">
    <div v-for="(item) in dataList" :key="item.xbjId">
      <div :class="`xbj_item_view ${selectXbjId == item.xbjId ? 'is_active' : ''}`" @click="openAction(item)">
        <div :style="`transform: rotate(${item.isOpen ? 90 : 0}deg)`" class="open_icon_img"></div>
        <div class="online_tag_view" :style="`background-color: ${item.isOnline == 1 ? '#67C23A' : 'red'};`"></div>
        <div class="name_tx">{{ item.xbjNickName }}（{{ getMessageReplyNum(item) }}）</div>
        <div v-if="item.isOpen == 1" class="sort_view" @click.stop="changeSortAction(item)">
          <div :style="`border-bottom-color: ${item.sortType != 0 ? '#1677ff' : '#666'}`" class="top_v"></div>
          <div :style="`border-top-color: ${item.sortType != 0 ? '#1677ff' : '#666'}`" class="bottom_v"></div>
        </div>
      </div>
      <div v-if="item.isOpen == 1">
        <div v-for="msgItem in getSortMessageList(item)" :class="`message_item_view ${msgIsActive(item, msgItem) ? 'is_active' : ''}`" :key="msgItem.externalUserid" @click="msgClick(item, msgItem)">
          <img class="user_img" :src="msgItem.userImg" alt="" />
          <div class="num_tx" v-if="msgItem.msgNum">{{ msgItem.msgNum > 99 ? '99+' : msgItem.msgNum }}</div>
          <div class="user_name_tx">{{ msgItem.userName }}</div>
          <div class="msg_tx">{{ msgItem.msgText }}</div>
          <div class="time_tx">{{ getLastMsgTime(msgItem.lastMsgTime) }}</div>
        </div>
        <div v-if="item.msgList.length == 0" style="color: #666;font-size: 12px;padding: 10px">暂无数据</div>
      </div>
    </div>
    <el-empty v-if="dataList.length == 0" :image="require('@/assets/icon_empty.png')" description="暂无数据" :image-size="100" style="padding-top: 50%;"></el-empty>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { Noti, Noti_Key } from '@/common/event';
import Trace from '@/common/trace';
const defaultUserInfo = { unionId: '', xbjId: '', userName: '', externalUserid: '', xbjNickName: '' };
moment.locale('zh');
export default {
  data() {
    return {
      dataList: [],
      selectXbjId: '',
      selectUserInfo: {}
    };
  },
  computed: {
    // 计算联系人列表 展示的时间
    getLastMsgTime() {
      return (dateString) => {
        const date = moment(dateString, 'YYYY-MM-DD HH:mm');
        const originalDateCopy = date.clone();
        // 获取当前日期
        const today = moment().startOf('day');
        // 计算与当前日期的差距
        const diffDays = today.diff(date.startOf('day'), 'days');
        if (diffDays === 0) {
          return '今天 ' + originalDateCopy.format('HH:mm');
        } else if (diffDays === 1) {
          return '昨天';
        } else if (date.isSame(today, 'week')) {
          return originalDateCopy.format('ddd');
        } else {
          return originalDateCopy.format('M-D');
        }
      };
    },
    // 计算联系人列表的排序
    getSortMessageList() {
      return (item) => {
        let list = item.msgList.slice();
        if (item.sortType != 1) {
          return list;
        }
        return list.sort((a, b) => {
          const date1 = moment(a.lastMsgTime, 'YYYY-MM-DD HH:mm');
          const date2 = moment(b.lastMsgTime, 'YYYY-MM-DD HH:mm');
          return date1.diff(date2, 'milliseconds');
        });
      };
    },
    // 计算是否选中
    msgIsActive() {
      return (item, msgItem) => {
        return this.selectUserInfo.externalUserid == msgItem.externalUserid && this.selectUserInfo.xbjId == item.xbjId;
      };
    },
    // 计算待回复会话数量
    getMessageReplyNum() {
      return (item) => {
        return item.msgList.filter((msgItem) => msgItem.msgNum).length;
      };
    }
  },
  watch: {
    //isSame 是否与上一次选择是同一个会话
    selectUserInfo(val, oldVal) {
      Trace.selectUserInfo = JSON.parse(JSON.stringify(val));
      Noti.$emit(Noti_Key.Select_User, { ...val, isSame: (val.externalUserid == oldVal.externalUserid && val.xbjId == oldVal.xbjId) });
    }
  },
  mounted() {
    // 小必姐下会话状态变化
    Noti.$on(Noti_Key.User_Change, this.userMessageChangeAction);
    // 小必姐下会话被删除
    Noti.$on(Noti_Key.User_Delete, this.userMessageDeletAction);
    // 小必姐在线状态发生变化
    Noti.$on(Noti_Key.Xbj_Online_Status_Change, this.xbjOnlineStatusChangeAction);
  },
  methods: {
    // 加载联系人列表
    loadXbjList(dataList) {
      let list = [...dataList];
      this.initDataList(list);
      this.dataList = list;
    },
    // 每次小必姐联系人列表更新 计算初始化选中逻辑
    initDataList(list) {
      if (!list.length) {
        return;
      }
      let mp = {};
      if (this.dataList.length) {
        this.dataList.forEach((item) => {
          mp[item.xbjId] = item;
        });
      }
      list.forEach((item, i) => {
        item.sortType = mp[item.xbjId] ? mp[item.xbjId].sortType : 0;
        item.isOpen = mp[item.xbjId] ? mp[item.xbjId].isOpen : false;
        item.isOnline = item.onlineStatus;
        item.msgList = item.msgList.sort((a, b) => {
          const date1 = moment(a.lastMsgTime, 'YYYY-MM-DD HH:mm');
          const date2 = moment(b.lastMsgTime, 'YYYY-MM-DD HH:mm');
          return date2.diff(date1, 'milliseconds');
        });
      });
      let selectUserInfo = defaultUserInfo;
      if (!this.selectUserInfo.externalUserid) {
        let xbjObj = list.find((item) => item.msgList.length);
        if (xbjObj) {
          xbjObj.isOpen = true;
          this.selectXbjId = xbjObj.xbjId;
          selectUserInfo = this.createSelectUserVo(xbjObj, xbjObj.msgList[0]);
        }
      } else {
        let isSelectMsgXbjObj = list.find((item) => this.selectUserInfo.xbjId == item.xbjId);
        if (!isSelectMsgXbjObj) {
          return;
        }
        let isSelectMsg = isSelectMsgXbjObj.msgList.find((item) => this.selectUserInfo.externalUserid == item.externalUserid);
        if (isSelectMsg) {
          return;
        }
        if (isSelectMsgXbjObj.msgList.length) {
          selectUserInfo = this.createSelectUserVo(isSelectMsgXbjObj, isSelectMsgXbjObj.msgList[0]);
        } else {
          let prevXbjObj = null;
          let nextXbjObj = null;
          let selectXbjObj = null;
          list.forEach((item, i) => {
            if (item.xbjId == isSelectMsgXbjObj.xbjId) {
              selectXbjObj = item;
            } else if (!selectXbjObj && item.msgList.length) {
              prevXbjObj = item;
            } else if (selectXbjObj && !nextXbjObj && item.msgList.length) {
              nextXbjObj = item;
            }
          });
          if (nextXbjObj) {
            nextXbjObj.isOpen = true;
            selectUserInfo = this.createSelectUserVo(nextXbjObj, nextXbjObj.msgList[0]);
          } else if (prevXbjObj) {
            prevXbjObj.isOpen = true;
            selectUserInfo = this.createSelectUserVo(prevXbjObj, prevXbjObj.msgList[prevXbjObj.msgList.length - 1]);
          } else {
            selectUserInfo = defaultUserInfo;
          }
        }
      }
      this.selectUserInfo = selectUserInfo;
    },
    // 设置选中selectUserInfo
    createSelectUserVo(xbjItem, msgItem) {
      return {
        xbjId: xbjItem.xbjId,
        isOnline: xbjItem.isOnline,
        unionId: msgItem.unionId,
        userName: msgItem.userName,
        externalUserid: msgItem.externalUserid,
        xbjNickName: xbjItem.xbjNickName
      };
    },
    // 打开小必姐分组
    openAction(item) {
      item.isOpen = !item.isOpen;
      this.selectXbjId = item.xbjId;
    },
    // 小必姐分组 列表排序按钮点击
    changeSortAction(item) {
      item.sortType = item.sortType == 1 ? 0 : 1;
    },
    // 小必姐分组下  联系人点击
    msgClick(xbjItem, msgItem) {
      if (this.selectUserInfo.externalUserid == msgItem.externalUserid && this.selectUserInfo.xbjId == xbjItem.xbjId) {
        return;
      }
      this.selectUserInfo = this.createSelectUserVo(xbjItem, msgItem);
      this.selectXbjId = xbjItem.xbjId;
    },
    // 会话被删除
    userMessageDeletAction(data) {
      let list = [...this.dataList];
      let obj = list.find((item) => item.xbjId == data.xbjId);
      if (!obj) {
        return;
      }
      let msgData = data.data;
      let findIndex = obj.msgList.findIndex((item) => (item.externalUserid == msgData.externalUserid));
      if (findIndex == -1) {
        return;
      }
      // 如果已选中当前消息 自动选择下一个
      if (this.selectUserInfo.externalUserid == msgData.externalUserid && this.selectUserInfo.xbjId == data.xbjId) {
        let nearFirst = null;
        let nearNext = null;
        let isSelectMsg = false;
        for (let i = 0; i < list.length; i++) {
          for (let j = 0; j < list[i].msgList.length; j++) {
            let obj = list[i].msgList[j];
            if (this.selectUserInfo.externalUserid == obj.externalUserid && this.selectUserInfo.xbjId == list[i].xbjId) {
              isSelectMsg = true;
              continue;
            }
            if (!isSelectMsg) {
              nearFirst = { msgObj: obj, xbjId: list[i].xbjId };
            } else if (!nearNext) {
              nearNext = { msgObj: obj, xbjId: list[i].xbjId };
            }
          }
        }
        let selectObj = null;
        if (nearNext && nearFirst && nearFirst.xbjId == data.xbjId && nearNext.xbjId != data.xbjId) {
          selectObj = nearFirst;
        } else if (nearNext) {
          selectObj = nearNext;
        } else if (nearFirst) {
          selectObj = nearFirst;
        }
        if (selectObj) {
          selectObj.isOpen = true;
          this.selectUserInfo = this.createSelectUserVo(selectObj, selectObj.msgObj);
        } else {
          this.selectUserInfo = defaultUserInfo;
        }
      }
      obj.msgList.splice(findIndex, 1);
      this.dataList = [...list];
    },
    // 会话变化
    userMessageChangeAction(data) {
      let list = [...this.dataList];
      let obj = list.find((item) => item.xbjId == data.xbjId);
      if (!obj) {
        return;
      }
      let msgData = data.data;
      let findIndex = obj.msgList.findIndex((item) => (item.externalUserid == msgData.externalUserid));
      if (findIndex != -1) {
        obj.msgList[findIndex] = msgData;
      } else {
        obj.msgList.push(msgData);
        obj.msgList.sort((a, b) => {
          const date1 = moment(a.lastMsgTime, 'YYYY-MM-DD HH:mm');
          const date2 = moment(b.lastMsgTime, 'YYYY-MM-DD HH:mm');
          return date2.diff(date1, 'milliseconds');
        });
      }
      this.dataList = [...list];
      if (this.selectUserInfo.externalUserid == msgData.externalUserid && this.selectUserInfo.xbjId == data.xbjId) {
        this.selectUserInfo = this.createSelectUserVo(obj, msgData);
      }
    },
    // 小必姐在线状态变化触发
    xbjOnlineStatusChangeAction(data) {
      try {
        let obj = this.dataList.find(item => item.xbjId == data.data.staffId);
        obj.isOnline = data.data.status;
      } catch (error) {

      }
    }
  }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 1px;
}

.list_view {
  border-right: 1px solid var(--boder-color);
  position: absolute;
  left: 0;
  top: 61px;
  width: 300px;
  bottom: 0;
  box-sizing: border-box;
  overflow-y: auto;

  .xbj_item_view {
    display: flex;
    align-items: center;
    cursor: pointer;
    // position: sticky;
    // left: 0;
    // top: 0;
    // z-index: 2;
    background-color: white;

    &:hover {
      background-color: #e5edff;
    }

    .open_icon_img {
      transition: transform ease-in 0.1s;
      width: 40px;
      height: 40px;
      background-image: url('~@/assets/icon_right.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
    }

    .name_tx {
      font-size: 14px;
      color: #333;
      max-width: 200px;
      text-align: justify;
    }

    .sort_view {
      display: flex;
      height: 100%;
      width: 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 0;
      margin-left: auto;
      cursor: pointer;

      .top_v {
        width: 0px;
        height: 0px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 6px solid #666;
        transform: scale(1.1);
        transform-origin: center;
      }

      .bottom_v {
        margin-top: 4px;
        width: 0px;
        height: 0px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid #666;
        transform: scale(1.1);
        transform-origin: center;
      }
    }

    .online_tag_view {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      margin-right: 4px;
      transform: translateY(2px);
    }
  }

  .message_item_view {
    padding: 0px 10px 0;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    min-height: 50px;
    font-size: 14px;
    color: #000;
    margin-top: 4px;

    &:hover {
      background-color: #1677ff1a;
    }

    .user_img {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 10px;
      top: 10px;
      border-radius: 50%;
      border: 1px solid #c4c0c0;
    }

    .num_tx {
      position: absolute;
      left: 32px;
      top: 4px;
      height: 20px;
      min-width: 20px;
      text-align: center;
      line-height: 20px;
      background-color: red;
      font-size: 12px;
      color: white;
      border-radius: 10px;
      transform: scale(0.8);
      transform-origin: center;
    }

    .user_name_tx {
      padding-top: 10px;
      line-height: 16px;
      margin-left: 56px;
      width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .msg_tx {
      margin-left: 56px;
      font-size: 12px;
      line-height: 14px;
      margin-top: 4px;
      color: #333;
      width: 150px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .time_tx {
      position: absolute;
      right: 10px;
      top: 30px;
      color: #666;
      line-height: 14px;
      font-size: 12px;
    }
  }

  .is_active {
    background-color: #e5edff;
  }

}
</style>